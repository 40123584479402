import { array, string } from "prop-types";
import { complement, isEmpty } from "ramda";
import React from "react";

import Heading from "../../components/Heading";
import Hero from "../../components/Hero/v4";
import Modular from "../../components/Modular";
import SEO from "../../components/SEO";
import Text from "../../components/Text";

const notEmpty = complement(isEmpty);

const ModularPage = ({ title, description, content, seoMetaTags }) => (
  <>
    <SEO tags={seoMetaTags} />
    <Hero
      title={<Heading size={1}>{title}</Heading>}
      content={<Text html>{description}</Text>}
    />
    {content.filter(notEmpty).map((item) => (
      <Modular key={item.id} {...item} type={item.__typename} />
    ))}
  </>
);

ModularPage.propTypes = {
  title: string,
  description: string,
  content: array,
  seoMetaTags: array,
};

export default ModularPage;
